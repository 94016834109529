import {
  Button,
  Drawer,
  DrawerContainer,
  DrawerContent,
  List,
  ListItem,
  Text,
} from '@elseu/sdu-titan';

const listItemPrefix = '•';
interface TaxviceUpsellDrawerProps {
  isShown: boolean;
  onDrawerToggle: (isShown: boolean) => void;
}
const TaxviceUpsellDrawer = ({ isShown, onDrawerToggle }: TaxviceUpsellDrawerProps) => {
  return (
    <Drawer
      isShown={isShown}
      position="right"
      testId="taxviceUpsellDrawer"
      width={480}
      onClose={() => onDrawerToggle(false)}
    >
      <DrawerContainer hasCloseButton header="Opslaan in Addify dossier">
        <DrawerContent>
          <Text color="grey80" spaceAfter={4} type="paragraph">
            Deze functionaliteit is alleen beschikbaar voor Addify klanten. Addify biedt je:
          </Text>
          <List spaceAfter={4}>
            <ListItem prefixAdornment={listItemPrefix}>Een gestroomlijnd werkproces</ListItem>
            <ListItem prefixAdornment={listItemPrefix}>Compliancy</ListItem>
            <ListItem prefixAdornment={listItemPrefix}>Inzicht in advieskansen</ListItem>
            <ListItem prefixAdornment={listItemPrefix}>360-graden klantbeeld</ListItem>
            <ListItem prefixAdornment={listItemPrefix}>Data optimalisatie</ListItem>
          </List>
          <Text color="grey80" spaceAfter={4} type="paragraph">
            Ben je benieuwd naar Addify?
          </Text>
          <Button isFullWidth href="https://www.sdu.nl/leadform/s/fiscaal/450">
            Gratis consultancy afspraak aanvragen
          </Button>
        </DrawerContent>
      </DrawerContainer>
    </Drawer>
  );
};

export { TaxviceUpsellDrawer };
