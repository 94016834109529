import { config } from '@/config';

import { UNSET_LOCALE } from '../helpers/statics';

export async function loadMessages(locale: string = config.defaultLocale) {
  // skip loading messages for the (non-existing) un-set locale
  if (locale === UNSET_LOCALE) {
    return;
  }

  const { messages } = await import(`./locales/${locale}/messages`);

  /** TODO: init other packages translation string by importing them from their folder */
  const { messages: readingListsMessages } = await import(
    `@elseu/sdu-titan-reading-lists-react/locales/${locale}/messages.js`
  );
  const { messages: contentRenderingMessages } = await import(
    `@elseu/sdu-titan-content-rendering/locales/${locale}/messages.js`
  );
  const { messages: pscMessages } = await import(
    `@elseu/sdu-titan-product-site-components/locales/${locale}/messages.js`
  );
  const { messages: searchMessages } = await import(
    `@elseu/sdu-titan-search/locales/${locale}/messages.js`
  );

  return {
    ...messages,
    ...readingListsMessages,
    ...contentRenderingMessages,
    ...pscMessages,
    ...searchMessages,
  };
}
