import { PageLoader } from '@elseu/sdu-titan-product-site-components';
import { useRouter } from 'next/router';
import { useAuthControls, useAuthInitialized, useAuthIsLoggedIn } from 'oidc-jwt-client';
import { useEffect } from 'react';

import { config } from '@/config';

export function ForceAuth({ children }: React.PropsWithChildren<any>) {
  const { authorize } = useAuthControls();
  const isLoggedIn = useAuthIsLoggedIn();
  const isInitialized = useAuthInitialized();
  const router = useRouter();

  const params = router.query;

  const shouldForceAuthentication =
    config.featureFlags.includes('WITH_FORCED_AUTHENTICATION') || params.forceAuth === 'true';

  useEffect(() => {
    if (shouldForceAuthentication && !isLoggedIn && isInitialized) {
      authorize({
        // comes from /login/[idp].tsx
        ...(params.idp && { fed_idp: params.idp as string }),
      });
    }
  }, [authorize, isInitialized, isLoggedIn, shouldForceAuthentication, params]);

  if (shouldForceAuthentication && !isLoggedIn) {
    return <PageLoader />;
  }

  return <>{children}</>;
}
