import type { ApolloCache } from '@apollo/client/cache/core/cache';
import type { NormalizedCacheObject } from '@apollo/client/cache/inmemory/types';
import type { Context } from 'react';
import { createContext } from 'react';

export type ApolloCacheControlCacheSnapshot = Record<string, NormalizedCacheObject>;

export class ApolloCacheControl {
  private cacheInstances = new Map<string, ApolloCache<NormalizedCacheObject>>();
  private storedExtractedCache = new Map<string, NormalizedCacheObject>();
  private static context: Context<ApolloCacheControl | null> | undefined;

  public static getContext() {
    if (!ApolloCacheControl.context) {
      ApolloCacheControl.context = createContext<ApolloCacheControl | null>(null);
    }

    return ApolloCacheControl.context;
  }

  public registerCache(key: string, cache: ApolloCache<NormalizedCacheObject>) {
    if (this.cacheInstances.has(key)) {
      const extractedCache = this.extractCache(key);
      if (extractedCache) {
        this.cacheInstances.set(key, cache.restore(extractedCache));
      }
      return;
    }
    this.cacheInstances.set(key, cache);
  }

  public extractCache(key: string): NormalizedCacheObject | null {
    const cache = this.cacheInstances.get(key);
    if (cache) {
      return cache.extract();
    }

    return null;
  }

  public getExtractedCache(key: string): NormalizedCacheObject | undefined {
    return this.storedExtractedCache.get(key);
  }

  public getSnapshot(): ApolloCacheControlCacheSnapshot {
    const allCacheExtracts: ApolloCacheControlCacheSnapshot = {};
    this.cacheInstances.forEach((value, key) => {
      allCacheExtracts[key] = value.extract();
    });
    return allCacheExtracts;
  }

  public restoreSnapshot(snapshot: ApolloCacheControlCacheSnapshot) {
    for (const key in snapshot) {
      this.storedExtractedCache.set(key, snapshot[key]);
    }
  }

  public seal(): void {
    this.cacheInstances.clear();
    this.storedExtractedCache.clear();
  }
}
