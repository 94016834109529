import { useContext } from 'react';

import { ApolloCacheControl } from './ApolloCacheControl';

export const useApolloCacheControl = (): ApolloCacheControl => {
  const context = useContext(ApolloCacheControl.getContext());
  if (!context) {
    throw new Error('Cannot use useApolloCacheController without context provider');
  }

  return context;
};
