import type { CombinedAnalyticsPlugin } from '@elseu/sdu-titan-product-site-components';

import { getDataLayer, pushToDataLayer } from './dataLayer';

export enum MoonlitEvent {
  SENTENCE = 'moonlit_sentence',
  ARGUMENT = 'moonlit_argument',
  COMBINED = 'moonlit_combined',
}

const getMoonlitToggleEvent = (moonlitLabel: string, isEnabled: boolean) => ({
  moonlitLabel,
  moonlitToggle: isEnabled ? 'enabled' : 'disabled',
  event: 'moonlit',
});

export const moonlitDataLayerPlugin = (): CombinedAnalyticsPlugin => {
  return {
    name: 'moonlitDataLayerAnalytics',
    initializeStart: ({ abort }) => {
      if (typeof window === 'undefined') {
        return abort('Cancel because server side rendering');
      }
    },
    initialize: () => getDataLayer(),
    track: ({ payload }) => {
      switch (payload.event) {
        case MoonlitEvent.SENTENCE:
          pushToDataLayer(getMoonlitToggleEvent('belangrijke zin', payload.properties.isEnabled));
          break;
        case MoonlitEvent.ARGUMENT:
          pushToDataLayer(getMoonlitToggleEvent('argument', payload.properties.isEnabled));
          break;
        case MoonlitEvent.COMBINED:
          pushToDataLayer({
            moonlitLabel: 'bothTogglesEnabled',
            moonlitToggle: payload.properties.isActive ? 'true' : 'false',
            event: 'moonlit',
          });
          break;
      }
    },
  };
};
