import type { DockItem, DockItemData } from '@elseu/sdu-titan-content-rendering';
import dynamic from 'next/dynamic';
import { useState } from 'react';

import { config } from '@/config';

import { TaxviceSharePluginDockIconItem } from '../TaxviceSharePlugin';

const TaxviceSharePluginDockComponents = dynamic(
  () => import(/* webpackChunkName: "TaxviceSharePlugin" */ '../TaxviceSharePlugin'),
  {
    ssr: false,
  },
);

export const shouldShowTaxvicePlugin = ({
  type,
  swsId,
  swsDocumentTitle,
}: DockItemData['data']): boolean =>
  !!(swsId && swsDocumentTitle) && !!type && ['Commentary', 'PracticeNote', 'Law'].includes(type);

const useTaxviceSharePlugin = (): DockItem => {
  const [isShown, setShown] = useState<boolean>(false);

  return {
    iconItem: {
      isShown: ({ data, canHavePersonalisationFeatures }) =>
        !!canHavePersonalisationFeatures && shouldShowTaxvicePlugin(data),
      render: (data) => (
        <TaxviceSharePluginDockIconItem itemData={data} onClick={() => setShown(true)} />
      ),
    },
    components: {
      isShown: ({ data, canHavePersonalisationFeatures }) =>
        !!canHavePersonalisationFeatures && shouldShowTaxvicePlugin(data),
      render: (data) => (
        <TaxviceSharePluginDockComponents
          baseUrl={config.taxviceShareBaseUrl}
          isShown={isShown}
          itemData={data}
          onDrawerToggle={setShown}
        />
      ),
    },
  };
};
export { useTaxviceSharePlugin };
